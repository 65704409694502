import React from 'react';
import { useRecoilValue } from 'recoil';

import {
  Player,
  PlayerGem,
  PlayerContent,
  PlayerTimer,
} from '@/ui-components/Player';

import useLayout from '@/use/useLayout';
import { gamePhaseState, turnState } from '@/framework/board';

import { opponentPlayerState } from '@/framework/player';

import { openModal } from '@/use/useModal';

export const OpponentPlayer = () => {
  const { layout } = useLayout();

  const opponentPlayer = {
    connected: useRecoilValue(opponentPlayerState.connected),
    id: useRecoilValue(opponentPlayerState.id),
    userId: useRecoilValue(opponentPlayerState.userId),
    colour: useRecoilValue(opponentPlayerState.colour),
    name: useRecoilValue(opponentPlayerState.name),
    country: useRecoilValue(opponentPlayerState.country),
    rejoin: useRecoilValue(opponentPlayerState.rejoin),
    score: useRecoilValue(opponentPlayerState.score),
    rank: useRecoilValue(opponentPlayerState.rank),
  };

  const turn = useRecoilValue(turnState);
  const gamePhase = useRecoilValue(gamePhaseState);

  const playerLayout = layout === 'portrait' ? 'inline' : 'centered';
  const canClick = opponentPlayer.userId && opponentPlayer.userId !== 'anon';
  const isTurn = gamePhase === 'playing' && opponentPlayer.id === turn;

  const handleClick = () => {
    openModal('user-info', {
      extra: { userId: opponentPlayer.userId },
    });
  };

  return (
    <Player
      turn={opponentPlayer.id === turn}
      layout={playerLayout}
      disconnected={!opponentPlayer.connected && gamePhase === 'playing'}
      onClick={canClick ? handleClick : undefined}
    >
      <PlayerGem type={opponentPlayer.colour} />
      <PlayerContent
        name={opponentPlayer.name}
        rank={opponentPlayer.rank}
        country={opponentPlayer.country}
        score={opponentPlayer.score}
        turnMessage="Opponent's turn"
        {...{ isTurn }}
      />
      {opponentPlayer.rejoin &&
      opponentPlayer.rejoin > 0 &&
      gamePhase !== 'over' ? (
        <PlayerTimer start={opponentPlayer.rejoin} />
      ) : null}
    </Player>
  );
};
