export const columns = 7;
export const rows = 6;

/**
 * Initial board width and height
 */
// export const initialWidth = 946;
// export const initialHeight = 580;

export const cellSize = 90;
export const gridWidth = cellSize * 7;
export const gridHeight = cellSize * 6;

export const boardWidth = gridWidth + 400;
export const boardHeight = gridHeight + 80;
export const boardRatio = boardWidth / boardHeight;
