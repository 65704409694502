import { useCallback } from 'react';
import classNames from 'classnames';

const useBem = (block: string) => {
  const bem = useCallback(
    (element: string, modifier?: any) => {
      const output = [];
      const base = element === '' ? block : `${block}__${element}`;
      output.push(base);

      const checkModifier = (m: any) => {
        if (typeof m === 'string') {
          output.push(`${base}--${m}`);
        } else if (Array.isArray(m)) {
          m.forEach(checkModifier);
        } else if (typeof m === 'object') {
          for (const prop in m) {
            if (m[prop]) {
              output.push(`${base}--${prop}`);
            }
          }
        }
      };

      checkModifier(modifier);

      return classNames(output);
    },
    [block],
  );

  return {
    bem,
  };
};

export default useBem;
