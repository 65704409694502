import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, useNavigate } from 'react-router-dom';

import Modal from '@/ui-components/Modal';
import Panel, { PanelClose } from '@/ui-components/Panel';
import { Tab, TabContent, Tabs } from '@/ui-components/Tabs';

import useBem from '@/use/useBem';

import { userSelector } from '@/framework/user';

import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const Login = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState('login');
  const { bem } = useBem('login');
  const loggedIn = useRecoilValue(userSelector.loggedIn);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => {
    navigate('/');
  };

  if (loggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <Modal isOpen={true} onRequestClose={handleClose}>
      <Panel className={bem('')}>
        <PanelClose onClick={handleClose} />
        <Tabs className={bem('tabs')} value={value} onChange={handleChange}>
          <Tab value="login">Login</Tab>
          <Tab value="sign-up">Sign up</Tab>
        </Tabs>
        <TabContent value={value} index="login">
          <LoginForm />
        </TabContent>

        <TabContent value={value} index="sign-up">
          <SignupForm />
        </TabContent>
      </Panel>
    </Modal>
  );
};

export default Login;
