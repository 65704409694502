import React from 'react';

import useBem from '@/use/useBem';
interface Props {
  /**
   * The content of the component.
   */
  children: React.ReactNode;

  /**
   * The value of the corresponding Tab. Must use the index of the Tab
   * when no value was passed to Tab.
   */
  value: String | Number;

  /**
   * Index of corresponding Tab
   */
  index: String | Number;
}

const TabContent: React.FC<Props> = (props) => {
  const { children, value, index } = props;
  const { bem } = useBem('tab-content');

  const hidden = value !== index;

  return (
    <div className={bem('')} {...{ hidden }}>
      {children}
    </div>
  );
};

export default TabContent;
