import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';
import Input from '@/ui-components/Input';
import ErrorsMessage from '@/ui-components/ErrorsMessage';

import { resetPassword, ChangePasswordType } from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Enter current password'),
  password: Yup.string().required('Enter new password'),
  passwordConfirmation: Yup.string()
    .required('Repeat new password')
    .test('passwordConfirmation', 'New passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: ChangePasswordType) => {
    try {
      await resetPassword(values);
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorsMessage {...{ errors }} />

      <FieldGroup label="Change password:">
        <Input
          type="password"
          placeholder="Current password"
          error={errors.currentPassword?.message}
          {...register('currentPassword')}
        />
        <Input
          type="password"
          placeholder="New password"
          error={errors.password?.message}
          {...register('password')}
        />
        <Input
          type="password"
          placeholder="Repeat new password"
          error={errors.passwordConfirmation?.message}
          {...register('passwordConfirmation')}
        />
      </FieldGroup>
      <ButtonGroup>
        <Button size="medium" type="submit" loading={isSubmitting}>
          Submit
        </Button>
      </ButtonGroup>
    </form>
  );
};

export default ChangePassword;
