import {
  ForgotPasswordValuesType,
  ResetPasswordValuesType,
} from '@/framework/user';

import * as auth from '@/api/auth.service';

export function forgotPassword(values: ForgotPasswordValuesType) {
  auth.forgotPassword(values);
}

export function resetPassword(values: ResetPasswordValuesType) {
  auth.resetPassword(values);
}
