import { atom } from 'recoil';

import { GameType } from '@/framework/game';

import { userPlayerState, opponentPlayerState } from '../player/playersState';
import { boardStates } from '../board/boardState';

export const gameIdState = atom<string | undefined>({
  key: 'gameIdState',
  default: undefined,
});

export const gameTypeState = atom<GameType | undefined>({
  key: 'gameTypeState',
  default: undefined,
});

export const gameStates = [
  gameIdState,
  gameTypeState,
  ...Object.values(userPlayerState),
  ...Object.values(opponentPlayerState),
  ...boardStates,
] as any;
