import React, { useEffect, useState } from 'react';

import Modal from '@/ui-components/Modal';
import Panel, { PanelClose } from '@/ui-components/Panel';
import ProfileScreen from '@/ui-components/ProfileScreen';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';

import * as profile from '@/api/profile.service';

const UserInfoModal = () => {
  const [user, setUser] = useState(null);
  const { bem } = useBem('user-info');
  const [isOpen, setModal, props] = useModal('user-info');

  const userId = props?.extra?.userId;

  const handleAfterOpen = () => {
    profile.getUserById(userId).then(({ data }) => {
      setUser(data);
    });
  };

  const handleAfterClose = () => {
    setUser(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onRequestClose={setModal.close}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      contentClassName={bem('')}
    >
      <Panel>
        <PanelClose onClick={setModal.close} />

        {user ? <ProfileScreen {...{ user }} /> : <Loading />}
      </Panel>
    </Modal>
  );
};

export default UserInfoModal;
