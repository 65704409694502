import React from 'react';
import classNames from 'classnames';

import ButtonRound from '@/ui-components/ButtonRound';
import Icon from '@/ui-components/Icon';
import { ButtonBaseType } from '@/ui-components/BaseButton';

import useBem from '@/use/useBem';

const PanelClose = ({ className = '', ...rest }: ButtonBaseType) => {
  const { bem } = useBem('panel');

  return (
    <div className={classNames([bem('close'), className])}>
      <ButtonRound {...rest}>
        <Icon name="close" style={{ width: 11 }} />
      </ButtonRound>
    </div>
  );
};

export default PanelClose;
