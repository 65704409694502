import React from 'react';
import classNames from 'classnames';
import { findClosest } from 'find-closest';

import { cellSize } from '@/constants';

import { GemType, GemState, gemAssets, borderAssets } from '@/framework/gem';

import useBem from '@/use/useBem';
import useGrid from '@/use/useGrid';

export interface GemProps {
  type?: GemType;
  state?: GemState;
  size?: 'default' | 'small';
  className?: string;

  /** If true sets gems style to loading */
  loading?: boolean;
  /** Adds a halo behind the gem */
  halo?: boolean;
  useGridScale?: boolean;
}

const Gem: React.FC<GemProps> = (props) => {
  const {
    type = 'neutral',
    className,
    size = 'default',
    halo,
    state = 'normal',
    useGridScale,
  } = props;
  const { bem } = useBem('gem');

  const { scale: gridScale } = useGrid();
  const gemAsset = gemAssets[type];
  const borderAsset = borderAssets['default'];

  const sharpScaleArr = [];
  const scalePrecision = 16;

  for (let i = 0; i <= scalePrecision; i++) {
    sharpScaleArr.push((1 / scalePrecision) * i);
  }

  const scale = findClosest(sharpScaleArr, gridScale);

  const style = useGridScale
    ? {
        width: `${cellSize * scale}px`,
        height: `${cellSize * scale}px`,
      }
    : {};

  const scaleStyle = useGridScale
    ? {
        transform: `scale(${scale})`,
        width: `${cellSize}px`,
        height: `${cellSize}px`,
      }
    : {};

  return (
    <div className={classNames(bem(''), className)} {...{ style }}>
      <div className={bem('scale')} style={scaleStyle}>
        <img
          src={gemAsset[size]['1x']}
          srcSet={`${gemAsset[size]['2x']} 2x`}
          className={bem('gem')}
        />
        <img
          style={{ opacity: state === 'selected' ? 1 : 0 }}
          src={borderAsset[size]['1x']}
          srcSet={`${borderAsset[size]['2x']} 2x`}
          className={bem('border')}
        />
        {halo && <div className={bem('halo')} />}
      </div>
    </div>
  );
};

export default Gem;
