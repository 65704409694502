import React from 'react';
import Flag from 'react-flagkit';
import { getName } from 'country-list';

import Typography from '@/ui-components/Typography';
import Icon from '@/ui-components/Icon';

import useBem from '@/use/useBem';

import { IUser } from '@/framework/user';

export type ProfileScreenProps = {
  user: IUser | null;
};

const ProfileScreen = (props: ProfileScreenProps) => {
  const { user } = props;
  const { bem } = useBem('profile-screen');

  if (!user) {
    return null;
  }

  return (
    <div className={bem('')}>
      <div className={bem('top')}>
        {/* <div className={bem('avatar')}>
          <Icon name="user" className={bem('avatar-icon')} />
        </div> */}

        <table className={bem('table')}>
          <tbody>
            <tr>
              <td>
                <Typography variant="m">Username</Typography>
              </td>
              <td>
                <Typography variant="m">
                  {user!.country && (
                    <span className={bem('flag')}>
                      <Flag country={user!.country.toUpperCase()} size={18} />
                    </span>
                  )}
                  {user!.username}
                </Typography>
              </td>
            </tr>
            {/* <tr>
              <td>
                <Typography variant="m">Rank</Typography>
              </td>
              <td>
                <Typography variant="m">43</Typography>
              </td>
            </tr> */}

            {user!.country && (
              <tr>
                <td>
                  <Typography variant="m">Country</Typography>
                </td>
                <td>
                  <Typography variant="m">
                    {getName(user!.country.toUpperCase())}
                  </Typography>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <Typography variant="m">Score</Typography>
              </td>
              <td>
                <Typography variant="m">{user!.score}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="m">Won / Lost</Typography>
              </td>
              <td>
                <Typography variant="m">
                  {user!.wins} / {user!.losses}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfileScreen;
