import axiosClient from './axios-client';

import {
  ChangePasswordType,
  UpdateCountryValuesType,
  EditAccountValuesType,
} from '@/framework/user';

/** Get your user info */
export const getUser = () => {
  return axiosClient.get('/api/user');
};

/** Get other user info */
export const getUserById = (userId: string) => {
  return axiosClient.get(`/api/users/${userId}`);
};

export const getPID = () => {
  return axiosClient.get('/api/u');
};

export const resetPassword = (values: ChangePasswordType) => {
  const { currentPassword, password, passwordConfirmation } = values;

  return axiosClient.put('/api/user/password', {
    current_password: currentPassword,
    password_confirmation:
      passwordConfirmation === '' ? undefined : passwordConfirmation, // I need undefined not a string like ''
    password: password === '' ? undefined : password, // I need undefined not a string like ''
  });
};

export const updateCountry = (values: UpdateCountryValuesType) => {
  const { code } = values;

  return axiosClient.put(`/api/user/country/${code}`);
};

export const updateProfile = (values: EditAccountValuesType) => {
  // : UpdateProfileValuesType
  const { username, country } = values;

  return axiosClient.put('/api/user/profile-information', {
    username,
    country,
  });
};
