import { useMemo } from 'react';
import useLayout from '@/use/useLayout';
import useGrid from '@/use/useGrid';
import useBoard from '@/use/useBoard';

const useBoardSide = () => {
  const { layout } = useLayout();
  const grid = useGrid();
  const board = useBoard();

  const width = useMemo(() => {
    if (layout === 'portrait') {
      return grid.width;
    }

    return (board.width - grid.width) / 2;
  }, [layout, grid.width, board.width]);

  const height = useMemo(() => {
    if (layout === 'portrait') {
      return (board.height - grid.height) / 2;
    }

    return grid.height;
  }, [layout, grid.height, board.height]);

  return {
    width,
    height,
  };
};

export default useBoardSide;
