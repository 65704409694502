import React from 'react';
import { useRecoilValue } from 'recoil';

import Panel from '@/ui-components/Panel';

import useBem from '@/use/useBem';
import { isMenuOpenState } from '@/use/useMenuState';

import Leaderboard from './Leaderboard';

const LeaderboardSide = () => {
  const { bem } = useBem('leaderboard');
  const isMenuOpen = useRecoilValue(isMenuOpenState);

  if (!isMenuOpen) return null;

  return (
    <div className={bem('', 'side')}>
      <Panel className={bem('panel')}>
        <Leaderboard />
      </Panel>
    </div>
  );
};

export default LeaderboardSide;
