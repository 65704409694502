import { getRecoil, setRecoil, resetRecoil } from 'recoil-nexus';

import * as auth from '@/api/auth.service';
import * as profile from '@/api/profile.service';

import {
  SignupValuesType,
  LoginValuesType,
  ChangePasswordType,
  EditAccountValuesType,
} from './types';

import * as userSelector from './userSelector';

export const logout = () => {
  const loggedIn = getRecoil(userSelector.loggedIn);
  if (!loggedIn) return;

  resetRecoil(userSelector.user);
  auth.logout();
};

export const login = async (values: LoginValuesType) => {
  try {
    await auth.login(values);
    const { data } = await profile.getUser();
    setRecoil(userSelector.user, data);
  } catch (err) {
    throw err;
  }
};

export const checkLogin = async () => {
  try {
    const { data } = await profile.getUser();
    setRecoil(userSelector.user, data);
  } catch (err) {
    throw err;
  }
};

export const signup = async (values: SignupValuesType) => {
  try {
    await auth.signup(values);
    const { data } = await profile.getUser();
    setRecoil(userSelector.user, data);
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (values: ChangePasswordType) => {
  try {
    await profile.resetPassword(values);
    const { data } = await profile.getUser();
    setRecoil(userSelector.user, data);
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async (values: EditAccountValuesType) => {
  try {
    await profile.updateProfile(values);
    const { data } = await profile.getUser();
    setRecoil(userSelector.user, data);
  } catch (err) {
    throw err;
  }
};
