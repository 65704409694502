import React from 'react';
import ReactModal, { Props } from 'react-modal';
import classNames from 'classnames';

import useLayout from '@/use/useLayout';
import useBem from '@/use/useBem';

export type ModalProps = Props & {
  contentClassName?: string;

  /**
   * In-game modal, z-index below the nav
   */
  inGame?: boolean;

  // Keep modal centered, even on mobile portrait
  isCentered?: boolean;

  padding?: 'small' | 'default';
};

const Modal: React.FC<ModalProps> = ({
  children,
  contentClassName,
  inGame,
  isCentered,
  padding = 'default',
  ...rest
}) => {
  const { bem } = useBem('modal');
  const { layout } = useLayout();

  return (
    <ReactModal
      portalClassName=""
      bodyOpenClassName={''}
      overlayElement={({ className, style, ...rest }, contentElement) => (
        <div
          className={bem('', [
            layout,
            `padding-${padding}`,
            { inGame, isCentered },
          ])}
          {...rest}
        >
          <div className={classNames(bem('content'), contentClassName)}>
            {contentElement}
          </div>
          <div className={bem('backdrop')} />
        </div>
      )}
      // shouldCloseOnOverlayClick={false}
      contentElement={({ className, style, ...rest }, children) => (
        <div className={bem('content')} {...rest}>
          {children}
        </div>
      )}
      {...rest}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
