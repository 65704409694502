import React, { useEffect } from 'react';
import { Stage } from 'react-pixi-fiber';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil';
import * as PIXI from 'pixi.js';

import { colors } from '@/constants';

import Game from './Game';

window.PIXI = PIXI;

const GameStage = () => {
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

  const canvasElement = document.getElementsByTagName('canvas')[0];

  const app = new PIXI.Application({
    view: canvasElement,
    backgroundColor: parseInt(`0x${colors.black}`),
    resolution: window.devicePixelRatio,
    width: window.innerWidth,
    height: window.innerHeight,
    // resizeTo: window, // This one is laggy
    autoDensity: true,
  });

  const handleResize = () => {
    app.renderer.resize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Stage {...{ app }}>
      <RecoilBridge>
        <Game />
      </RecoilBridge>
    </Stage>
  );
};

export default GameStage;
