import * as Colyseus from 'colyseus.js';

let host = window.location.hostname;

if (['localhost', '0.0.0.0', '127.0.0.1'].includes(host)) {
  host = `ws://${host}:2567`;
} else {
  host = `wss://${host}/ws`;
}

const colyseusClient = new Colyseus.Client(host);

export default colyseusClient;
