import React from 'react';
import classNames from 'classnames';

import useBem from '@/use/useBem';
import useLayout from '@/use/useLayout';
import useBoardSide from '@/use/useBoardSide';

type Props = {
  className?: string;
  contentClassName?: string;
  onClick?(): void;
};
const Centered: React.FC<Props> = ({
  children,
  className,
  contentClassName,
  ...rest
}) => {
  const { layout } = useLayout();
  const { bem } = useBem('centered');
  const boardSide = useBoardSide();

  const padding = layout === 'landscape' ? boardSide.width : undefined;

  return (
    <div
      className={classNames([bem('', layout), className])}
      {...rest}
      style={{ paddingLeft: padding, paddingRight: padding }}
    >
      <div className={bem('wrap')}>
        <div
          className={classNames([bem('content'), contentClassName])}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Centered.defaultProps = {
  className: '',
};

export default Centered;
