import React from 'react';

const logo = {
  normal: {
    '1x': require(`@/assets/images/logo/logo-full.png?size=214`),
    '2x': require(`@/assets/images/logo/logo-full.png?size=428`),
  },
  small: {
    '1x': require(`@/assets/images/logo/logo-full.png?size=130`),
    '2x': require(`@/assets/images/logo/logo-full.png?size=260`),
  },
};
type LogoProps = {
  size?: 'small' | 'normal';
};

const Logo = (props: LogoProps) => {
  const { size = 'normal' } = props;
  const asset = logo[size];

  return <img src={asset['1x']} srcSet={`${asset['2x']} 2x`} />;
};

export default Logo;
