import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { GameType, gameIdState, gameTypeState } from '@/framework/game';

const useStartGame = () => {
  const navigate = useNavigate();
  const setGameId = useSetRecoilState(gameIdState);
  const setGameType = useSetRecoilState(gameTypeState);

  const startGame = (gameId: string, type: GameType) => {
    setGameId(gameId);
    setGameType(type);
    navigate(`/${gameId}`);
  };

  return startGame;
};

export default useStartGame;
