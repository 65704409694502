import { useSetRecoilState } from 'recoil';

import * as colyseus from '@/colyseus/colyseus.service';
import { setupRoom } from '@/colyseus/colyseus-room';

import { openModal, closeModal } from '@/use/useModal';
import { isMenuOpenState } from '@/use/useMenuState';

import useStartGame from './useStartGame';
import useExitGame from './useExitGame';

import { GameType, createGame } from '@/framework/game';

const useFindGame = () => {
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const startGame = useStartGame();
  const { clearGame, exitGame } = useExitGame();

  const findGame = async (type: GameType) => {
    /**
     * Cancelled flag, allows to stop async request,
     * if user closed the search modal
     */
    let cancelled = false;

    /**
     * Abandon any previous game if present
     */
    exitGame();

    if (type === 'random') {
      openModal('game-random', {
        onRequestClose: () => {
          cancelled = true;
          closeModal();
          clearGame();
        },
      });
    }

    if (type === 'invite') {
      openModal('game-invite', {
        onRequestClose: () => {
          cancelled = true;
          closeModal();
          exitGame();
        },
      });
    }

    try {
      /**
       * API request to create / start game
       * Once it's created, returns gameId
       */
      const gameId = await createGame(type);
      if (cancelled) return;

      const room = await colyseus.join(gameId);
      if (cancelled) return;

      if (type === 'random') {
        setupRoom(room, () => {
          if (cancelled) return;
          closeModal();
          startGame(gameId, type);
          setIsMenuOpen(false);
        });
      }

      if (type === 'invite') {
        startGame(gameId, type);

        setupRoom(room, () => {
          if (cancelled) return;
          closeModal();
          setIsMenuOpen(false);
        });
      }
    } catch (err) {
      throw err;
    }
  };

  return findGame;
};

export default useFindGame;
