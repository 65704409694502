import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Menu from '@/ui-components/Menu';
import { LeaderboardModal, LeaderboardSide } from '@/ui-components/Leaderboard';
import Overlay from '@/ui-components/Overlay';
import Nav from '@/ui-components/Nav';
import EndgameModal from '@/ui-components/EndgameModal';
import Login from '@/ui-components/Login';
import Game from '@/ui-components/Game';
import GameInterface from '@/ui-components/GameInterface';
import AccountModal from '@/ui-components/AccountModal';
import ForgotPasswordModal from '@/ui-components/ForgotPasswordModal';
import RandomGameModal from '@/ui-components/RandomGameModal';
import ConnectModal from '@/ui-components/ConnectModal';
import GameNotFoundModal from '@/ui-components/GameNotFoundModal';
import ResignModal from '@/ui-components/ResignModal';
import ConfirmExit from '@/ui-components/ConfirmExit';
import InviteGameModal from '@/ui-components/InviteGameModal';
import ResetPassword from '@/ui-components/ResetPassword';
import UserInfoModal from '@/ui-components/UserInfoModal';
import AnnouncementModal from '@/ui-components/AnnouncementModal';
import Loading from '@/ui-components/Loading';
import Modal from '@/ui-components/Modal';

import useLayout from '@/use/useLayout';
import { usePid } from '@/use/usePid';
import { useCheckLogin } from '@/use/useCheckLogin';

const App = () => {
  const { layout } = useLayout();
  const { isLoading: isPidLoading } = usePid();
  const { isLoading: isLoginLoading } = useCheckLogin();

  const isLoading = isPidLoading || isLoginLoading;

  if (isLoading) {
    return (
      <Modal isOpen={true}>
        <Loading />
      </Modal>
    );
  }

  return (
    <>
      <Nav />
      {layout === 'desktop' && <LeaderboardSide />}

      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<AccountModal />} />
        <Route path="/forgot-password" element={<ForgotPasswordModal />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route path="/:gameId" element={<Game />} />
      </Routes>

      <Overlay />
      <GameInterface />

      <LeaderboardModal />

      <RandomGameModal />
      <InviteGameModal />

      <ConnectModal />
      <GameNotFoundModal />
      <EndgameModal />
      <ResignModal />
      <ConfirmExit />
      <UserInfoModal />
      <AnnouncementModal />
    </>
  );
};

export default App;
