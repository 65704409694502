import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCodeList } from 'country-list';
import { useRecoilValue } from 'recoil';

import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';
import Input from '@/ui-components/Input';
import Select from '@/ui-components/Select';
import ErrorsMessage from '@/ui-components/ErrorsMessage';
import StatusMessage from '@/ui-components/StatusMessage';

import {
  updateProfile,
  EditAccountValuesType,
  userState,
} from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  username: Yup.string(),
  country: Yup.string(),
});

const EditAccount = () => {
  const username = useRecoilValue(userState.username);
  const country = useRecoilValue(userState.country);

  const countries = getCodeList();

  const {
    register,
    handleSubmit,
    setError,
    getFieldState,
    resetField,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<EditAccountValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username,
      country,
    },
  });

  const onSubmit = async (values: EditAccountValuesType) => {
    console.log('values', values);
    try {
      await updateProfile(values);
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  const renderOption = (value: string) => {
    return (
      <option value={value} key={value}>
        {countries[value]}
      </option>
    );
  };

  const renderOptions = () => {
    return Object.keys(countries).map(renderOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSubmitSuccessful && (
        <StatusMessage variant="success">
          Details updated successfully!
        </StatusMessage>
      )}

      <ErrorsMessage {...{ errors }} />

      <FieldGroup label="New username:">
        <Input
          placeholder="Username"
          autoComplete="off"
          error={errors.username?.message}
          {...register('username')}
          isClearable={getFieldState('username').isDirty}
          onClear={() => {
            resetField('username');
          }}
        />
      </FieldGroup>
      <FieldGroup label="Country or flag:">
        <Select
          placeholder="Select country"
          error={errors.country?.message}
          {...register('country')}
        >
          <option value=""></option>
          {renderOptions()}
        </Select>
      </FieldGroup>

      <ButtonGroup>
        <Button size="medium" type="submit" loading={isSubmitting}>
          Submit
        </Button>
      </ButtonGroup>
    </form>
  );
};

export default EditAccount;
