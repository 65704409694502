import { resign } from '@/colyseus/colyseus-room';

const useResignGame = () => {
  const resignGame = () => {
    console.log('resignGame');
    resign();
  };

  return resignGame;
};

export default useResignGame;
