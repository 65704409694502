import React from 'react';
import { useRecoilValue } from 'recoil';

import useBem from '@/use/useBem';
import { isMenuOpenState } from '@/use/useMenuState';

const Overlay = () => {
  const isMenuOpen = useRecoilValue(isMenuOpenState);
  const { bem } = useBem('overlay');

  return <div className={bem('', { hidden: !isMenuOpen })} />;
};

export default Overlay;
