import React from 'react';
import { Container } from 'react-pixi-fiber';

export type Props = {
  children: React.ReactNode;
  x?: number;
  onClick: () => void;
};

const Column = ({ children, onClick, x }: Props) => {
  return (
    <Container
      interactive
      buttonMode
      sortableChildren
      pointertap={onClick}
      {...{ x }}
    >
      {children}
    </Container>
  );
};

export default Column;
