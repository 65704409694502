import React from 'react';

import Modal from '@/ui-components/Modal';
import Panel, { PanelClose } from '@/ui-components/Panel';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';

import Leaderboard from './Leaderboard';

const LeaderboardModal = () => {
  const { bem } = useBem('leaderboard');
  const [isOpen, setModal] = useModal('leaderboard');

  return (
    <Modal
      padding="small"
      isOpen={isOpen}
      onRequestClose={setModal.close}
      contentClassName={bem('', 'page')}
    >
      <Panel className={bem('panel')}>
        <PanelClose onClick={setModal.close} />
        <Leaderboard />
      </Panel>
    </Modal>
  );
};

export default LeaderboardModal;
