import React from 'react';
import { useRecoilValue } from 'recoil';

import SquareButton from '@/ui-components/SquareButton';
import Icon from '@/ui-components/Icon';

import useBem from '@/use/useBem';
import useLayout from '@/use/useLayout';
import { gamePhaseState } from '@/framework/board';

import useBoard from '@/use/useBoard';
import useGrid from '@/use/useGrid';
import useBoardSide from '@/use/useBoardSide';
import { isMenuOpenState } from '@/use/useMenuState';
import { openModal } from '@/use/useModal';

import { UserPlayer } from './UserPlayer';
import { OpponentPlayer } from './OpponentPlayer';

const GameInterface = () => {
  const { bem } = useBem('game-interface');
  const { layout } = useLayout();
  const grid = useGrid();

  const isMenuOpen = useRecoilValue(isMenuOpenState);
  const gamePhase = useRecoilValue(gamePhaseState);

  const board = useBoard();
  const boardSide = useBoardSide();

  const style = {
    width: layout === 'portrait' ? `${grid.width}px` : `${board.width}px`,
    height: `${board.height}px`,
    left:
      layout === 'portrait'
        ? `${(board.width - grid.width) / 2}px`
        : `${board.x - board.width / 2}px`,
    top: `${board.y - board.height / 2}px`,
  };

  const sideStyle = {
    width: layout === 'portrait' ? undefined : boardSide.width,
    height: layout === 'portrait' ? boardSide.height : undefined,
  };

  const renderAction = () => {
    if (gamePhase === 'over') {
      return (
        <SquareButton
          elevation={0}
          onClick={() => {
            openModal('endgame');
          }}
          icon={<Icon name="options" />}
        >
          Options
        </SquareButton>
      );
    }

    return (
      <SquareButton
        elevation={0}
        onClick={() => {
          openModal('resign');
        }}
        icon={<Icon name="flag" />}
      >
        Resign
      </SquareButton>
    );
  };

  return (
    <div className={bem('', [{ active: !isMenuOpen }, layout])} {...{ style }}>
      <div className={bem('side', '1')} style={sideStyle}>
        <OpponentPlayer />
      </div>

      <div className={bem('side', '2')} style={sideStyle}>
        <UserPlayer />

        <div className={bem('action')}>{renderAction()}</div>
      </div>
    </div>
  );
};

export default GameInterface;
