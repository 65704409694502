import axiosClient from './axios-client';
import {
  SignupValuesType,
  LoginValuesType,
  ForgotPasswordValuesType,
  ResetPasswordValuesType,
} from '@/framework/user';

/**
 * Cookie
 * Cross site requests
 * Confirming that all the request coming from the app
 */
export const setupXSRF = () => {
  axiosClient.get('/sanctum/csrf-cookie');
};

export const login = (values: LoginValuesType) => {
  const { email, password } = values;
  return axiosClient.post('/api/login', {
    email,
    password,
  });
};

export const signup = (values: SignupValuesType) => {
  const { username, email, password } = values;
  return axiosClient.post('/api/register', {
    username,
    email,
    password,
    password_confirmation: password,
  });
};

export const logout = () => {
  return axiosClient.post('/api/logout');
};

export const forgotPassword = (values: ForgotPasswordValuesType) => {
  const { email } = values;

  return axiosClient.post('/api/forgot-password', {
    email,
  });
};

export const resetPassword = (values: ResetPasswordValuesType) => {
  const { email, resetToken, password, passwordConfirmation } = values;

  return axiosClient.post('/api/reset-password', {
    email,
    token: resetToken,
    password,
    password_confirmation: passwordConfirmation,
  });
};
