import React from 'react';
import classNames from 'classnames';

import Icon from '@/ui-components/Icon';

import useBem from '@/use/useBem';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Item to add to the end of the input
   */
  suffix?: React.ReactNode;
  error?: boolean | string;
  variant?: 'm' | 's' | 'xs';
  isClearable?: boolean;
  onClear?: () => void;
};

const Input = React.forwardRef(
  (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      suffix,
      type = 'text',
      className = '',
      error,
      variant = 'm',
      isClearable,
      onClear,
      ...rest
    } = props;
    const { bem } = useBem('input');

    const suffixEl = isClearable ? (
      <Icon name="close" onClick={onClear} className={bem('clear')} />
    ) : (
      suffix
    );

    return (
      <div
        className={classNames([
          bem('', [{ error: error, suffix: suffixEl }, variant]),
          className,
        ])}
      >
        <input className={bem('input')} {...{ ref, type }} {...rest} />
        {suffixEl ? <div className={bem('suffix')}>{suffixEl}</div> : null}
      </div>
    );
  },
);

export default Input;
