import { atom } from 'recoil';

import { IPlayer2 } from './types';

const playerStateFacotry = (type: string) => {
  return {
    colour: atom<IPlayer2['colour'] | undefined>({
      key: `colour${type}State`,
      default: undefined,
    }),
    connected: atom<IPlayer2['connected'] | undefined>({
      key: `connected${type}State`,
      default: undefined,
    }),
    userId: atom<IPlayer2['userId'] | undefined>({
      key: `userId${type}State`,
      default: undefined,
    }),
    id: atom<IPlayer2['id'] | undefined>({
      key: `id${type}State`,
      default: undefined,
    }),
    name: atom<IPlayer2['name'] | undefined>({
      key: `name${type}State`,
      default: undefined,
    }),
    rematch: atom<IPlayer2['rematch'] | undefined>({
      key: `rematch${type}State`,
      default: undefined,
    }),
    score: atom<IPlayer2['score'] | undefined>({
      key: `score${type}State`,
      default: undefined,
    }),
    rank: atom<IPlayer2['rank'] | undefined>({
      key: `rank${type}State`,
      default: undefined,
    }),
    country: atom<IPlayer2['country'] | undefined>({
      key: `country${type}State`,
      default: undefined,
    }),
    rejoin: atom<IPlayer2['rejoin'] | undefined>({
      key: `rejoin${type}State`,
      default: undefined,
    }),
  };
};

export const userPlayerState = playerStateFacotry('user');
export const opponentPlayerState = playerStateFacotry('opponent');
