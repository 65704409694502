import { useState, useEffect } from 'react';
import { checkLogin } from '@/framework/user';

export const useCheckLogin = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkLogin()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return {
    isLoading,
  };
};
