import React from 'react';
import classNames from 'classnames';

import BaseButton, { ButtonBaseType } from '@/ui-components/BaseButton';

import useBem from '@/use/useBem';

const ButtonRound = ({ className = '', children, ...rest }: ButtonBaseType) => {
  const { bem } = useBem('button-round');

  return (
    <BaseButton {...rest} className={classNames([bem(''), className])}>
      <div className={bem('content')}>{children}</div>
    </BaseButton>
  );
};

export default ButtonRound;
