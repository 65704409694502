import React from 'react';

import Typography from '@/ui-components/Typography';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';

import { usePlayer } from './Player';
import PlayerName from './PlayerName';

type PlayerContentProps = {
  name?: string;
  score?: number;
  country?: string;
  rank?: number;
  isTurn?: boolean;
  turnMessage?: string;
};

const PlayerContent = (props: PlayerContentProps) => {
  const { country, name, score, rank, isTurn, turnMessage } = props;
  const { bem } = useBem('player');
  const { loading } = usePlayer();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={bem('content')}>
      <div className={bem('text')}>
        <PlayerName {...{ country, name, rank }} />

        {score && (
          <Typography variant="s" className={bem('score')} component="div">
            ({score})
          </Typography>
        )}
      </div>

      <Typography
        variant="xs"
        className={bem('turn')}
        component="div"
        style={{ opacity: isTurn ? 1 : 0 }}
      >
        {turnMessage}
      </Typography>
    </div>
  );
};

export default PlayerContent;
