import React from 'react';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel, { PanelClose } from '@/ui-components/Panel';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';

const GameNotFoundModal: React.FC = () => {
  const { bem } = useBem('not-found');
  const [isOpen, _, props] = useModal('not-found');

  return (
    <Modal {...props} {...{ isOpen }}>
      <Panel className={bem('')}>
        <PanelClose onClick={props?.onRequestClose} />
        <Typography variant="l" component="div">
          Game not found
        </Typography>
      </Panel>
    </Modal>
  );
};

export default GameNotFoundModal;
