import { useCallback } from 'react';

import useModal from '@/use/useModal';

const useAnnouncement = () => {
  const [isOpen, setModal] = useModal('announcement');

  const close = useCallback(() => {
    setModal.close();
    localStorage.setItem('announcement_status', '1');
  }, [setModal.close]);

  const open = useCallback(() => {
    const announcement_status = localStorage.getItem('announcement_status');

    if (!announcement_status) {
      setModal.open();
    }
  }, [setModal.open]);

  return {
    isOpen,
    close,
    open,
  };
};

export default useAnnouncement;
