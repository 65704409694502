export const textures = {
  bg: require(`@/assets/images/board/bg.jpg`).src,
  'bg-seamless': require(`@/assets/images/board/bg-seamless.jpg`).src,
  'corner-bottom-left': require(`@/assets/images/board/corner-bottom-left.png`)
    .src,
  'corner-bottom-right':
    require(`@/assets/images/board/corner-bottom-right.png`).src,
  'corner-top-left': require(`@/assets/images/board/corner-top-left.png`).src,
  'corner-top-right': require(`@/assets/images/board/corner-top-right.png`).src,
  'plank-bottom': require(`@/assets/images/board/plank-bottom.png`).src,
  'plank-left': require(`@/assets/images/board/plank-left.png`).src,
  'plank-right': require(`@/assets/images/board/plank-right.png`).src,
  'plank-top': require(`@/assets/images/board/plank-top.png`).src,
  hole: require(`@/assets/images/board/hole.png`).src,

  'broken-mask': require(`@/assets/images/gems/broken-mask.jpg`).src,
  'broken-shadow': require(`@/assets/images/gems/broken-shadow.png`).src,
  'border-default': require(`@/assets/images/gems/border-default.png`).src,
};
