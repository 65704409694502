import React from 'react';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel from '@/ui-components/Panel';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';

const ConnectModal: React.FC = () => {
  const { bem } = useBem('connect');
  const [isOpen, _, props] = useModal('connect');

  return (
    <Modal {...props} {...{ isOpen }}>
      <Panel className={bem('')}>
        <div className={bem('wrap')}>
          <Typography className={bem('h')} variant="l" component="div">
            Connecting to the game...
          </Typography>

          <Loading />
        </div>
      </Panel>
    </Modal>
  );
};

export default ConnectModal;
