import { rematch } from '@/colyseus/colyseus-room';

const useRematchGame = () => {
  const rematchGame = () => {
    rematch();
  };

  return rematchGame;
};

export default useRematchGame;
