import React from 'react';
import { Container } from 'react-pixi-fiber';

import useBoard from '@/use/useBoard';

import Border from './Border';
import Grid from './Grid';

const Board = () => {
  const { x, y } = useBoard();

  return (
    <Container {...{ x, y }}>
      <Border />
      <Grid />
    </Container>
  );
};

export default Board;
