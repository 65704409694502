import { Sprite, Container } from 'react-pixi-fiber';
import { animated } from 'react-spring';
import { Globals } from '@react-spring/core';
import * as PIXI from 'pixi.js';

// export interface AnimatedGlobals {
//   // Defaults to \`Date.now\`
//   now?: () => number;
//   // To support colors like "red". Defaults to \`{}\`
//   colorNames?: { [key: string]: number };
//   // Usually the "div" of the platform. Defaults to \`undefined\`
//   defaultElement?: any;
//   // Update the values of the affected nodes. Required
//   applyAnimatedValues?: (node: any, props: object) => boolean | void;
//   // Wrap the \`transform\` property of an animated style
//   createAnimatedTransform?: (transform: any) => any;
//   // Wrap the \`style\` property of an animated component
//   createAnimatedStyle?: (style: any) => any;
//   // Create the \`ref\` API of an animated component
//   createAnimatedRef?: (
//     node: { current: any },
//     mounted?: { current: boolean },
//     forceUpdate?: () => void,
//   ) => { current: any };
//   // Defaults to \`window.requestAnimationFrame\`
//   requestAnimationFrame?: (onFrame: () => void) => number;
//   // Defaults to \`window.cancelAnimationFrame\`
//   cancelAnimationFrame?: (handle: number) => void;
//   // Called on every frame. Defaults to \`undefined\`
//   manualFrameloop?: () => void;
//   // Custom string interpolation. Defaults to \`undefined\`
//   interpolation?: (config: object) => (input: number) => number | string;
// }

/**
 * Had to bring it from 0.14 version of react-pixi-fibre.
 * The beta version didn't have `applyProps` function.
 * Had to use beta version, because normal version was
 * breaking on mobile - some error with recoil.
 */
export function defaultApplyProps(instance, oldProps, newProps) {
  Object.keys(oldProps)
    .concat(Object.keys(newProps))
    .filter(unique)
    .filter(not(includingReservedProps))
    .forEach((propName) => {
      const defaultValue = DEFAULT_PROPS[propName];
      const currentValue = instance[propName];
      const newValue = newProps[propName];

      // Set value if defined
      if (typeof newValue !== 'undefined') {
        setPixiValue(instance, propName, newValue);
      }
      // Reset to default value (if it is defined) when display object had prop set and no longer has
      else if (typeof currentValue !== 'undefined' && typeof defaultValue !== 'undefined') {
        warning(false, `setting default value: ${propName} was ${currentValue} is ${newValue} for %O`, instance);
        setPixiValue(instance, propName, defaultValue);
      } else {
        warning(false, `ignoring prop: ${propName} was ${instance[propName]} is ${newValue} for %O`, instance);
      }
    });
}

export function applyProps(instance, oldProps, newProps) {
  if (typeof instance._customApplyProps === 'function') {
    instance._customApplyProps(instance, oldProps, newProps);
  } else {
    defaultApplyProps(instance, oldProps, newProps);
  }
}

function ApplyAnimatedValues(instance, props) {
  if (instance instanceof PIXI.DisplayObject) {
    applyProps(instance, {}, props);
  } else {
    return false;
  }
}

function mapStyle(style) {
  return style;
}

// The new way
Globals.assign({
  applyAnimatedValues: ApplyAnimatedValues,
  requestAnimationFrame: (cb) => global.requestAnimationFrame(cb),
  cancelAnimationFrame: (cb) => global.cancelAnimationFrame(cb),
  createAnimatedStyle: mapStyle,
});

const ReactPixiFiberAnimated = {
  Sprite: animated(Sprite),
  Container: animated(Container),
};

export default ReactPixiFiberAnimated;
