import React from 'react';
import { Container } from 'react-pixi-fiber';
import { columns, rows, cellSize, gridHeight } from '@/constants';

import useGrid from '@/use/useGrid';
import { turn } from '@/colyseus/colyseus-room';

import Column from './Column';
import Cell from './Cell';

const Grid = () => {
  const { scale, x, y } = useGrid();
  const columnWidth = cellSize;
  const cellHeight = cellSize;

  const renderCell = (cIndex: number, rIndex: number) => {
    const y = gridHeight - cellHeight * (rIndex + 1);
    const zIndex = rows - rIndex - 1;

    return (
      <Cell
        width={columnWidth}
        height={cellHeight}
        key={`cell-${cIndex}${rIndex}`}
        {...{ y, zIndex, cIndex, rIndex }}
      />
    );
  };

  const renderColumn = (_: undefined, cIndex: number) => {
    const handleClick = () => {
      turn(cIndex);
    };

    return (
      <Column
        onClick={handleClick}
        x={columnWidth * cIndex}
        key={`col-${cIndex}`}
      >
        {[...Array(rows)].map((_: undefined, rIndex: number) => {
          return renderCell(cIndex, rIndex);
        })}
      </Column>
    );
  };

  return (
    <Container {...{ x, y, scale }}>
      {[...Array(columns)].map(renderColumn)}
    </Container>
  );
};

export default Grid;
