export enum Gems {
  red = 'red',
  blue = 'blue',
  neutral = 'neutral',
  gold = 'gold',
  silver = 'silver',
  bronze = 'bronze',
  lava = 'lava',
  ice = 'ice',
  caviar = 'caviar',
  void = 'void',
}

export type GemType = keyof typeof Gems;

export type GemAssets = {
  [type in GemType]?: any;
};

export interface IGem {
  type: GemType;
}

export type GemState = 'normal' | 'selected' | 'broken';
