import React, { useMemo, useState } from 'react';
import * as PIXI from 'pixi.js';
import { Container, Sprite } from 'react-pixi-fiber';

import useLoader from '@/game/useLoader';
import { GemType, GemState } from '@/framework/gem';

export type Props = {
  type?: GemType;
  state?: GemState;
  width: number;
  height: number;
};

const Gem = ({ width, height, type, state = 'normal' }: Props) => {
  const { loader, loaded } = useLoader();
  const [maskRef, setMaskRef] = useState<any>(null);

  if (!loaded) return null;
  if (!type) return null;

  const name = useMemo(() => {
    if (state === 'normal') {
      return `gem-${type}`;
    }

    return `gem-${type}`;
  }, [type, state]);

  const texture = loader.resources[name].texture;
  const borderTexture = loader.resources['border-default'].texture;
  const mask = loader.resources['broken-mask'].texture;
  const shadow = loader.resources['broken-shadow'].texture;

  // const { width: textureW, height: textureH } = texture;
  // const ratio = textureW / textureH;
  const anchor = new PIXI.Point(0, 0);

  return (
    <Container>
      {state === 'selected' && (
        <Sprite texture={borderTexture} {...{ anchor, width, height }} />
      )}
      {state === 'broken' && (
        <>
          <Sprite
            ref={(mask) => setMaskRef(mask)}
            texture={mask}
            {...{ anchor, width, height }}
          />
          <Sprite texture={shadow} {...{ anchor, width, height }} />
        </>
      )}
      <Sprite
        mask={state === 'broken' ? maskRef : null}
        {...{ anchor, texture, width, height }}
      />
    </Container>
  );
};

export default Gem;
