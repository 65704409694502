import React, { useRef, useEffect, useState } from 'react';

interface Props {
  /**
   * The sub text under content.
   */
  name?: React.ReactNode;
}

const Icon: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({ name, className = '', ...rest }) => {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>> | any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        // Changing this line works fine to me
        ImportedIconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!@/assets/images/icons/${name}.svg`)
        ).default;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();

    /**
     * Fixes memory leak
     */
    return () => {
      setLoading(false);
    };
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;

    return (
      <div {...rest} className={`icon ${className}`}>
        <ImportedIcon />
      </div>
    );
  }
  return null;
};

export default Icon;
