import React from 'react';
import Icon from '@/ui-components/Icon';
import Typography from '@/ui-components/Typography';
import Flag from 'react-flagkit';

import useBem from '@/use/useBem';

export type LeaderboardItemProps = {
  username: string;
  country: string;
  score: number;
  total: number;
  wins: number;
  losses: number;
  rank: number;
};

const LeaderboardItem = (props: LeaderboardItemProps) => {
  const { username, score, rank, country } = props;
  const { bem } = useBem('leaderboard');

  return (
    <tr className={bem('item')}>
      <td className={bem('rank')}>
        <Typography variant="s" component="span">
          {rank}
        </Typography>

        {rank === 1 ? (
          <Icon className={bem('crown')} name="crown-gold" />
        ) : null}
        {rank === 2 ? (
          <Icon className={bem('crown')} name="crown-silver" />
        ) : null}
        {rank === 3 ? (
          <Icon className={bem('crown')} name="crown-bronze" />
        ) : null}
      </td>
      <td className={bem('name')}>
        <span className={bem('flag')}>
          <Flag country={country.toUpperCase()} size={15} />
        </span>
        <Typography variant="s" component="span">
          {username}
        </Typography>
      </td>
      <td className={bem('score')}>
        <Typography variant="s" component="span">
          {score}
        </Typography>
      </td>
    </tr>
  );
};

export default LeaderboardItem;
