import { atom, selector } from 'recoil';

import { BoardCell, Move } from './types';
import { IPlayer2 } from '../player';

import {
  GamePhaseType,
  EndgameReason,
  WinningCombinationType,
  Score,
} from '@/framework/game';

import { columns, rows } from '@/constants';

export const turnState = atom<IPlayer2['id'] | undefined>({
  key: 'turnState',
  default: undefined,
});

export const gamePhaseState = atom<GamePhaseType | undefined>({
  key: 'gamePhaseState',
  default: undefined,
});

export const historyState = atom<Move[] | undefined>({
  key: 'historyState',
  default: undefined,
});

export const lastMoveSelector = selector<Move | undefined>({
  key: 'lastMoveSelector',
  get: ({ get }) => {
    const history = get(historyState);
    return history?.[history?.length - 1];
  },
});

export const lastCellSelector = selector<BoardCell | undefined>({
  key: 'lastCellSelector',
  get: ({ get }) => {
    const move = get(lastMoveSelector);

    if (!move) return undefined;

    const { col, row } = move;
    const cellAtom = boardState[col][row];

    if (!cellAtom) return undefined;

    return get(cellAtom);
  },
  set: ({ set, get }, newValue) => {
    const move = get(lastMoveSelector);

    if (!move) return;

    const { col, row } = move;
    const cellAtom = boardState[col][row];

    if (!cellAtom) return;

    set(cellAtom, newValue);
  },
});

export const boardState = [...Array(columns).keys()].map((cIndex) =>
  [...Array(rows).keys()].map((rIndex) =>
    atom<BoardCell | undefined>({
      key: `boardState${cIndex}${rIndex}`,
      default: undefined,
    }),
  ),
);

export const winnerIdState = atom<IPlayer2['id'] | undefined>({
  key: 'winnerIdState',
  default: undefined,
});

export const endgameReasonState = atom<EndgameReason | undefined>({
  key: 'endgameReasonState',
  default: undefined,
});

export const winningComboState = atom<WinningCombinationType | undefined>({
  key: 'winningComboState',
  default: undefined,
});

export const endgameScoreChangeState = atom<Score | undefined>({
  key: 'endgameScoreChangeState',
  default: undefined,
});

export const boardStates = [
  turnState,
  gamePhaseState,
  winnerIdState,
  endgameReasonState,
  winningComboState,
  endgameScoreChangeState,
  historyState,
  ...boardState.reduce((a, b) => {
    return [...a, ...b];
  }),
] as any;

export const rematchStates = [
  winnerIdState,
  endgameReasonState,
  winningComboState,
  endgameScoreChangeState,
  ...boardState.reduce((a, b) => {
    return [...a, ...b];
  }),
] as any;
