import { Gems, GemAssets, GemType } from './types';

const requireRetina = (gem: string) => {
  return {
    default: {
      '1x': require(`@/assets/images/${gem}.png?size=90`),
      '2x': require(`@/assets/images/${gem}.png?size=180`),
    },
    small: {
      '1x': require(`@/assets/images/${gem}.png?size=60`),
      '2x': require(`@/assets/images/${gem}.png?size=120`),
    },
  };
};

export const gemAssets: GemAssets = Object.fromEntries(
  Object.values(Gems).map((value: GemType) => [
    value,
    requireRetina(`gems/gem-${value}`),
  ]),
);

export const borderAssets = {
  default: requireRetina('gems/border-default'),
};
