import { UseFormSetError, FieldValues, Path } from 'react-hook-form';

export type ApiErrors = { [k: string]: string[] };

export type ConvertedApiError = {
  name: string;
  message: string;
};

export const setApiErrors = <T extends FieldValues>(
  errors: ApiErrors,
  setError: UseFormSetError<T>,
) => {
  for (const field in errors) {
    if (errors.hasOwnProperty(field)) {
      const message = errors[field][0];
      setError(field as Path<T>, {
        type: 'manual',
        message,
      });
    }
  }
};
