import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Player, PlayerGem, PlayerContent } from '@/ui-components/Player';

import useLayout from '@/use/useLayout';
import { gamePhaseState, turnState } from '@/framework/board';

import { userPlayerState } from '@/framework/player';

import { openModal } from '@/use/useModal';

export const UserPlayer = () => {
  const { layout } = useLayout();

  const userPlayer = {
    id: useRecoilValue(userPlayerState.id),
    userId: useRecoilValue(userPlayerState.userId),
    colour: useRecoilValue(userPlayerState.colour),
    name: useRecoilValue(userPlayerState.name),
    country: useRecoilValue(userPlayerState.country),
    score: useRecoilValue(userPlayerState.score),
  };

  const turn = useRecoilValue(turnState);
  const gamePhase = useRecoilValue(gamePhaseState);
  const playerLayout = layout === 'portrait' ? 'inline' : 'centered';

  const canClick = userPlayer.userId && userPlayer.userId !== 'anon';
  const isTurn = gamePhase === 'playing' && userPlayer.id === turn;

  const handleClick = () => {
    openModal('user-info', {
      extra: { userId: userPlayer.userId },
    });
  };

  return (
    <Player
      turn={userPlayer.id === turn}
      layout={playerLayout}
      onClick={canClick ? handleClick : undefined}
    >
      <PlayerGem type={userPlayer.colour} />
      <PlayerContent
        name={userPlayer.name}
        country={userPlayer.country}
        score={userPlayer.score}
        turnMessage="Your turn"
        {...{ isTurn }}
      />
      {/* <PlayerTimer /> */}
    </Player>
  );
};
