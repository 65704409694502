import React from 'react';
import { TilingSprite, Container, PointLike } from 'react-pixi-fiber';

import useLoader from '@/game/useLoader';

export type Props = {
  anchor: PointLike;
  position: 'top' | 'bottom' | 'left' | 'right';
  width: number;
  height: number;
  x?: number;
  y?: number;
};

const BorderLine = ({ anchor, position, width, height, x, y }: Props) => {
  const { loader, loaded } = useLoader();

  if (!loaded) return null;

  const name = `plank-${position}`;
  const texture = loader.resources[name].texture;

  return (
    <Container>
      <TilingSprite {...{ anchor, texture, width, height, x, y }} />
    </Container>
  );
};

BorderLine.defaultProps = {
  y: 0,
  x: 0,
};

export default BorderLine;
