import React, { useMemo } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { TilingSprite, Container } from 'react-pixi-fiber';

import useLayout from '@/use/useLayout';
import useBoard from '@/use/useBoard';

import useLoader from './useLoader';

const Background = () => {
  const [ww, wh] = useWindowSize();
  const { loader, loaded } = useLoader();
  const board = useBoard();
  const { layout } = useLayout();

  // Bleeding of the border out of the background / board
  const borderBleed = 10;

  const width = useMemo(() => {
    if (layout === 'desktop') {
      return board.width - borderBleed * 2; /* for border */
    }
    return ww;
  }, [layout, ww, board.width]);

  const height = useMemo(() => {
    if (layout === 'desktop') {
      return board.height - borderBleed * 2; /* for border */
    }

    return wh;
  }, [layout, wh, board.height]);

  const x = useMemo(() => {
    if (layout === 'desktop') {
      return board.x;
    }

    return ww / 2;
  }, [layout, ww, board.x]);

  const y = useMemo(() => {
    if (layout === 'desktop') {
      return board.y;
    }

    return wh / 2;
  }, [layout, ww, board.y]);

  const anchor = 0.5;

  if (!loaded) return null;
  const texture = loader.resources['bg-seamless'].texture;

  return (
    <Container {...{ x, y }}>
      <TilingSprite {...{ anchor, texture, width, height }} />
    </Container>
  );
};

export default Background;
