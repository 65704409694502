import React from 'react';
import { Container } from 'react-pixi-fiber';

import BorderLine from './BorderLine';
import BorderCorner from './BorderCorner';

import useLayout from '@/use/useLayout';
import useBoard from '@/use/useBoard';

const Border = () => {
  const { layout } = useLayout();
  const { width: w, height: h } = useBoard();
  const visible = layout === 'desktop';

  // Cut out a bit of plank, that sits behind the corner
  // Otherwise a few dirty pixels are visible around rounded corner
  const plankCut = 30;

  return (
    <Container {...{ visible }}>
      <BorderLine
        anchor={[0.5, 0]}
        position="top"
        width={w - plankCut}
        height={16}
        y={-h / 2}
      />
      <BorderLine
        anchor={[0.5, 1]}
        position="bottom"
        width={w - plankCut}
        height={16}
        y={h / 2}
      />
      <BorderLine
        anchor={[0, 0.5]}
        position="left"
        width={19}
        height={h - plankCut}
        x={-w / 2}
      />
      <BorderLine
        anchor={[1, 0.5]}
        position="right"
        width={19}
        height={h - plankCut}
        x={w / 2}
      />

      <BorderCorner
        anchor={[0, 0]}
        positionV="top"
        positionH="left"
        x={-w / 2}
        y={-h / 2}
      />
      <BorderCorner
        anchor={[1, 0]}
        positionV="top"
        positionH="right"
        x={w / 2}
        y={-h / 2}
      />
      <BorderCorner
        anchor={[1, 1]}
        positionV="bottom"
        positionH="right"
        x={w / 2}
        y={h / 2}
      />
      <BorderCorner
        anchor={[0, 1]}
        positionV="bottom"
        positionH="left"
        x={-w / 2}
        y={h / 2}
      />
    </Container>
  );
};

export default Border;
