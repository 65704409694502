import React from 'react';
import ripped from '@/assets/images/flag/flag-ripped.png';
import normal from '@/assets/images/flag/flag-normal.png';

export interface IFlag {
  /**
   * Type of flag
   */
  type: 'normal' | 'ripped';
}

const Flag: React.FC<IFlag & React.HTMLAttributes<HTMLDivElement>> = ({ type, className = '' }) => {
  const map = {
    normal: normal,
    ripped: ripped,
  };

  return (
    <div className={`flag ${className}`}>
      <img src={map[type]} />
    </div>
  );
};

export default Flag;
