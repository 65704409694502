import React from 'react';
import classNames from 'classnames';

import useBem from '@/use/useBem';

import Icon from '@/ui-components/Icon';

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  error?: boolean | string;
  variant?: 'm' | 's' | 'xs';
};

const Select = React.forwardRef(
  (props: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
    const { className = '', error, variant = 'm', ...rest } = props;
    const { bem } = useBem('select');
    return (
      <div
        className={classNames([
          bem('', [{ error: error }, variant]),
          className,
        ])}
      >
        <select className={bem('select')} {...{ ref }} {...rest} />
        <div className={bem('chevron')}>
          <Icon name="chevron-down" style={{ width: '14px', height: '14px' }} />
        </div>
      </div>
    );
  },
);

export default Select;
