import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useSearchParams } from 'react-router-dom';

import Page from '@/ui-components/Page';
import Centered from '@/ui-components/Centered';
import Panel from '@/ui-components/Panel';
import Input from '@/ui-components/Input';
import ErrorsMessage from '@/ui-components/ErrorsMessage';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';
import Button from '@/ui-components/Button';
import Typography from '@/ui-components/Typography';
import StatusMessage from '@/ui-components/StatusMessage';

import useBem from '@/use/useBem';
import { resetPassword } from '@/use/useForgotPassword';

import { ResetPasswordValuesType } from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  password: Yup.string().required('Enter new password'),
  passwordConfirmation: Yup.string()
    .required('Repeat new password')
    .test('passwordConfirmation', 'New passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = () => {
  const { resetToken } = useParams();
  const { bem } = useBem('reset-password');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<ResetPasswordValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: ResetPasswordValuesType) => {
    try {
      const submitValues = {
        email,
        resetToken,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      };
      await resetPassword(submitValues);
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  return (
    <Page>
      <Centered>
        <Panel className={bem('')}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className={bem('h')} variant="xl" mb="medium">
              Reset password
            </Typography>

            {isSubmitSuccessful ? (
              <StatusMessage variant="success">
                You've successfully reset your password.
              </StatusMessage>
            ) : (
              <>
                <ErrorsMessage {...{ errors }} className={bem('errors')} />

                <FieldGroup label="Enter new password:">
                  <Input
                    type="password"
                    autoComplete="off"
                    placeholder="New password"
                    error={errors.password?.message}
                    {...register('password')}
                  />
                  <Input
                    type="password"
                    autoComplete="off"
                    placeholder="Repeat new password"
                    error={errors.passwordConfirmation?.message}
                    {...register('passwordConfirmation')}
                  />
                </FieldGroup>

                <ButtonGroup>
                  <Button
                    size="medium"
                    type="submit"
                    color="red"
                    loading={isSubmitting}
                  >
                    Reset password
                  </Button>
                </ButtonGroup>
              </>
            )}
          </form>
        </Panel>
      </Centered>
    </Page>
  );
};

export default ResetPassword;
