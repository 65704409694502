import './assets/scss/main.scss';

import React from 'react';
import RecoilNexus from 'recoil-nexus';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

import * as auth from '@/api/auth.service';

import App from './App';
import GameStage from './game/GameStage';

auth.setupXSRF();

// initGame();

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#app');

render(
  <RecoilRoot>
    <RecoilNexus />
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <GameStage />
  </RecoilRoot>,
  document.getElementById('app'),
);
