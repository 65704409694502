import React, { useMemo } from 'react';
import { createContext } from '@chakra-ui/react-utils';

import { IPlayer2 } from '@/framework/player';

import useBem from '@/use/useBem';
interface PlayerContext {
  loading?: boolean;
  size?: 'default' | 'small';
  turn?: boolean;
  disconnected?: boolean;
}
interface PlayerProps extends PlayerContext {
  layout?: 'centered' | 'inline';
  player?: IPlayer2 | null;
  children?: React.ReactNode;
  onClick?(): void;
}

const [PlayerProvider, usePlayer] = createContext<PlayerContext>({
  strict: false,
  name: 'PlayerContext',
});

export { usePlayer };

const Player = (props: PlayerProps) => {
  const {
    player,
    loading,
    layout = 'centered',
    turn,
    disconnected,
    onClick,
    ...rest
  } = props;
  const { bem } = useBem('player');

  const context = useMemo(
    () => ({ loading, turn, disconnected }),
    [loading, turn, disconnected],
  );

  return (
    <PlayerProvider value={context}>
      <div
        className={bem('', [layout, { disconnected, onClick }])}
        {...rest}
        {...{ onClick }}
      />
    </PlayerProvider>
  );
};

export default Player;
