import React from 'react';
import Timer from '@/ui-components/Timer';
import Typography from '@/ui-components/Typography';

import useBem from '@/use/useBem';

import { usePlayer } from './Player';

type PlayerTimerProps = {
  start: number;
};

const PlayerTimer = (props: PlayerTimerProps) => {
  const { start } = props;
  const { bem } = useBem('player');
  const { turn, size } = usePlayer();

  const active = turn;

  return (
    <Typography variant="xs" className={bem('timer')}>
      Disconnected... <Timer {...{ size, active, start }} />
    </Typography>
  );
};

export default PlayerTimer;
