import React from 'react';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';
import { useExitGame } from '@/use/useGame';

const ConfirmExit = () => {
  const { bem } = useBem('confirm-exit');
  const [isOpen, setModal, props] = useModal('confirm-exit');
  const { exitGame } = useExitGame();

  return (
    <Modal
      isCentered
      {...props}
      {...{ isOpen }}
      onRequestClose={setModal.close}
    >
      <Panel className={bem('')}>
        <PanelClose onClick={setModal.close} />
        <Typography className={bem('h')} variant="l" component="div">
          Are you sure you want to leave this game?
        </Typography>
        <Typography className={bem('text')} variant="m" component="div">
          Not finishing the game will result in a loss.
        </Typography>

        <ButtonGroup className={bem('buttons')}>
          <Button
            size="medium"
            color="red"
            to="/"
            onClick={() => {
              setModal.close();
              exitGame();
            }}
          >
            Leave
          </Button>
        </ButtonGroup>
      </Panel>
    </Modal>
  );
};

export default ConfirmExit;
