import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Cookies from 'js-cookie';
import * as profile from '@/api/profile.service';

import { pidState } from './usePidState';

export const usePid = () => {
  const [pid, setPid] = useRecoilState(pidState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pid) {
      setIsLoading(false);
    } else {
      profile.getPID().then(({ data }) => {
        setPid(data.pid);
        Cookies.set('pid', data.pid, {sameSite: "None", secure: true});
        setIsLoading(false);
      });
    }
  }, []);

  return {
    pid,
    isLoading,
  };
};
