import React from 'react';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';
import { useResignGame } from '@/use/useGame';

const ResignModal: React.FC = () => {
  const { bem } = useBem('resign-modal');
  const [isOpen, setModal, props] = useModal('resign');
  const resignGame = useResignGame();

  return (
    <Modal
      isCentered
      {...props}
      {...{ isOpen }}
      onRequestClose={setModal.close}
    >
      <Panel className={bem('')}>
        <PanelClose onClick={setModal.close} />
        <Typography variant="xl" component="div">
          Are you sure you <br />
          wish to resign?
        </Typography>

        <ButtonGroup className={bem('buttons')}>
          <Button
            size="medium"
            color="red"
            onClick={() => {
              resignGame();
              setModal.close();
            }}
          >
            Resign
          </Button>

          <Button size="medium" onClick={setModal.close}>
            Cancel
          </Button>
        </ButtonGroup>
      </Panel>
    </Modal>
  );
};

export default ResignModal;
