import { atom, useRecoilState } from 'recoil';
import * as PIXI from 'pixi.js';

import { textures } from '@/constants';
import { gemAssets, GemType } from '@/framework/gem';

const loaderState = atom({
  key: 'loaderState',
  default: false,
});

const useLoader = () => {
  const [loaded, setLoaded] = useRecoilState(loaderState);
  const loader = PIXI.Loader.shared;

  function handleLoadComplete() {
    setLoaded(true);
  }

  // function handleLoadProgress(loader: any, resource: any) {
  // console.log(loader.progress + '%loader', resource);
  // }

  function loadAssets() {
    let key: keyof typeof textures;
    let gemKey: GemType;

    for (key in textures) {
      loader.add(key, textures[key]);
    }

    for (gemKey in gemAssets) {
      loader.add(`gem-${gemKey}`, gemAssets[gemKey]['default']['2x'].src);
    }

    loader.onComplete.add(handleLoadComplete);
    // loader.onProgress.add(handleLoadProgress);

    loader.load();
  }

  return {
    loader,
    loaded,
    loadAssets,
  };
};

export default useLoader;
