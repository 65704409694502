import React from 'react';
import PropTypes from 'prop-types';

import Typography, { ITypographyProps } from '@/ui-components/Typography';

import useBem from '@/use/useBem';
import useBreakpoint from '@/use/useBreakpoint';

type Props = {
  value: string;
  selected?: boolean;
  onChange?: (a: string) => void;
  children: React.ReactNode;
};

const Tab: React.FC<Props> = (props) => {
  const { children, onChange, value, selected } = props;
  const { breakpoint } = useBreakpoint();
  const { bem } = useBem('tab');

  let typeSize: ITypographyProps['variant'] = 'm';

  // if (breakpoint.d) typeSize = 'l';

  return (
    <div
      className={bem('', { selected: selected })}
      onClick={() => {
        onChange?.(value);
      }}
    >
      <Typography variant={typeSize} component="span">
        {children}
      </Typography>
    </div>
  );
};

export default Tab;
