import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@/ui-components/Button';
import Link from '@/ui-components/Link';
import Input from '@/ui-components/Input';
// import SocialButton from '@/ui-components/SocialButton';
import ErrorsMessage from '@/ui-components/ErrorsMessage';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';

import useBem from '@/use/useBem';

import { login, LoginValuesType } from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  email: Yup.string().required('Required email or username'),
  password: Yup.string().required('Required password'),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { bem } = useBem('login-form');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: LoginValuesType) => {
    try {
      await login(values);
      navigate('/');
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  return (
    <div className={bem('')}>
      {/* <div className={bem('social')}>
        <SocialButton className={bem('social-btn')} variant="facebook">
          Continue with Facebook
        </SocialButton>
        <SocialButton className={bem('social-btn')} variant="google">
          Continue with Google
        </SocialButton>
      </div> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ErrorsMessage {...{ errors }} />

        <FieldGroup>
          <Input
            placeholder="Email or username"
            autoComplete="username"
            error={errors.email?.message}
            {...register('email')}
          />
          <Input
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            error={errors.password?.message}
            {...register('password')}
          />
        </FieldGroup>

        <div className={bem('forgot')}>
          <Link to="/forgot-password">Forgot Login?</Link>
        </div>

        <ButtonGroup>
          <Button size="medium" type="submit" loading={isSubmitting}>
            Login
          </Button>
        </ButtonGroup>
      </form>
    </div>
  );
};

export default LoginForm;
