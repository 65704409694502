import colyseusClient from '@/colyseus/colyseus-client';
import Cookies from 'js-cookie';

export const join = async (gameId: string) => {
  const room = await colyseusClient.joinById(gameId);

  Cookies.set(`${gameId}_sesh`, room.id, {sameSite: "None", secure: true, expires: 1});
  Cookies.set(`${gameId}_userCookieId`, room.sessionId, {sameSite: "None", secure: true, expires: 1 });

  return room;
};

export const reconnect = async (gameId: string) => {
  const sessionId = Cookies.get(`${gameId}_userCookieId`);

  if (!sessionId) throw `Couldn't connect, session expired`;

  const room = await colyseusClient.reconnect(gameId, sessionId);

  return room;
};
