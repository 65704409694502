import { useMediaQueries } from '@react-hook/media-query';

const useOrientation = () => {
  const { matches } = useMediaQueries({
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
  });

  return {
    orientation: matches,
  };
};

export default useOrientation;
