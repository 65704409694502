import axiosClient, { responseBody } from '@/api/axios-client';

import { GameType, GameId } from './types';

type GetGame = {
  type: GameType;
};

export const getGame = (gameId: GameId): Promise<GetGame> => {
  return axiosClient.get(`/api/games/${gameId}`).then(responseBody);
};

export const createGame = (type: GameType = 'invite'): Promise<GameId> => {
  return axiosClient.get(`/api/game/${type}`).then(responseBody);
};
