import React, { useState } from 'react';

import useInterval from './useInterval';

type TimerProps = {
  start: number;
  active?: Boolean;
  size?: 'default' | 'small';
  className?: string;
};

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
};

const Timer = ({
  start,
  active = false,
  className = '',
  size = 'default',
  ...rest
}: TimerProps) => {
  const [status, setStatus] = useState(STATUS.STARTED);
  const [secondsRemaining, setSecondsRemaining] = useState(start);

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  );

  return (
    <span
      className={`timer timer--${active ? 'active' : ''} ${className}`}
      {...rest}
    >
      {secondsRemaining}
    </span>
  );
};

export default Timer;
