import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from '@/ui-components/Modal';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Input from '@/ui-components/Input';
import ErrorsMessage from '@/ui-components/ErrorsMessage';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';
import Button from '@/ui-components/Button';
import Typography from '@/ui-components/Typography';
import StatusMessage from '@/ui-components/StatusMessage';

import useBem from '@/use/useBem';
import { forgotPassword } from '@/use/useForgotPassword';

import { userSelector, ForgotPasswordValuesType } from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  email: Yup.string().email('Not valid email').required('Required email'),
});

const ForgotPasswordModal = () => {
  const navigate = useNavigate();
  const loggedIn = useRecoilValue(userSelector.loggedIn);
  const { bem } = useBem('forgot-password');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<ForgotPasswordValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: ForgotPasswordValuesType) => {
    try {
      await forgotPassword(values);
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  const handleClose = () => {
    navigate('/');
  };

  if (loggedIn) return null;

  return (
    <Modal isOpen={true} onRequestClose={handleClose}>
      <Panel className={bem('')}>
        <PanelClose onClick={handleClose} />
        <Typography className={bem('h')} variant="xl">
          Forgot password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSubmitSuccessful && (
            <StatusMessage variant="success">
              Reset link sent. Check your email.
            </StatusMessage>
          )}

          <ErrorsMessage {...{ errors }} className={bem('errors')} />

          <FieldGroup>
            <Input
              placeholder="Enter your email"
              autoComplete="email"
              error={errors.email?.message}
              {...register('email')}
            />
          </FieldGroup>

          <ButtonGroup>
            <Button size="medium" type="submit" loading={isSubmitting}>
              Send reset link
            </Button>
          </ButtonGroup>
        </form>
      </Panel>
    </Modal>
  );
};

export default ForgotPasswordModal;
