import React from 'react';

import Modal from '@/ui-components/Modal';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Typography from '@/ui-components/Typography';
import Link from '@/ui-components/Link';
import Icon from '@/ui-components/Icon';

import useBem from '@/use/useBem';
import useAnnouncement from './useAnnouncement';

const AnnouncementModal = () => {
  const { isOpen, close } = useAnnouncement();
  const { bem } = useBem('announcement');

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <Panel className={bem('')}>
        <PanelClose onClick={close} />
        <Typography className={bem('h')} variant="l" component="div">
          First announcement!
        </Typography>
        <Typography>
          Welcome to our first release! You can now battle on the leaderboard
          with random opponents and invite your friends to games.
        </Typography>
        <Typography>
          Look forward to custom gems, tournaments and more updates in the
          future!
        </Typography>

        <Typography variant="l">Join our socials:</Typography>
        <div className={bem('social')}>
          <Link
            to="https://discord.gg/WzPZbNAGYK"
            target="__blank"
            variant="flat"
            leftIcon={<Icon name="discord" />}
          >
            Discord
          </Link>
          <Link
            to="https://www.instagram.com/gems4.io/"
            target="__blank"
            variant="flat"
            leftIcon={<Icon name="instagram" />}
          >
            Instagram
          </Link>
        </div>
      </Panel>
    </Modal>
  );
};

export default AnnouncementModal;
