import React from 'react';
import classNames from 'classnames';

import useBem from '@/use/useBem';

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className = '' } = props;
  const { bem } = useBem('page');

  return <div className={classNames([bem(''), className])}>{children}</div>;
};

export default Page;
