import { atom } from 'recoil';
import { setRecoil, getRecoil } from 'recoil-nexus';

export type ModalProps = {
  onRequestClose?(): void;
  // Any extra props to pass to modal
  extra?: any;
};

export type ModalState = {
  modalId: string;
  props?: ModalProps;
};

export const modalState = atom<ModalState | null>({
  key: 'modalState',
  default: null,
});

export const openModal = (modalId: string, props?: ModalProps) => {
  setRecoil(modalState, { modalId, props });
};

export const closeModal = (modalId?: string) => {
  const modal = getRecoil(modalState);

  if (!modalId) {
    setRecoil(modalState, null);
    return;
  }

  if (modalId === modal?.modalId) {
    setRecoil(modalState, null);
  }
};
