import React from 'react';
import classNames from 'classnames';

import Typography from '@/ui-components/Typography';
import BaseButton, { ButtonBaseType } from '@/ui-components/BaseButton';

import useBem from '@/use/useBem';

type LinkType = ButtonBaseType & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  variant?: 'text' | 'flat';
};

const Link = ({
  children,
  className,
  leftIcon,
  rightIcon,
  variant = 'text',
  ...rest
}: LinkType) => {
  const { bem } = useBem('link');
  const typeVariant = variant === 'flat' ? 's' : 'm';

  return (
    <BaseButton
      className={classNames([bem('', [variant]), className])}
      {...rest}
    >
      {leftIcon && <div className={bem('leftIcon')}>{leftIcon}</div>}
      {children && (
        <Typography variant={typeVariant} component="span">
          {children}
        </Typography>
      )}
      {rightIcon && <div className={bem('rightIcon')}>{rightIcon}</div>}
    </BaseButton>
  );
};

export default Link;
