import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, useNavigate } from 'react-router-dom';

import Modal from '@/ui-components/Modal';
import { Tab, TabContent, Tabs } from '@/ui-components/Tabs';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';
import ProfileScreen from '@/ui-components/ProfileScreen';

import EditAccount from './EditAccount';
import ChangePassword from './ChangePassword';

import useBem from '@/use/useBem';

import { userSelector, logout } from '@/framework/user';

const AccountModal = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState('profile');
  const { bem } = useBem('account');
  const loggedIn = useRecoilValue(userSelector.loggedIn);
  const user = useRecoilValue(userSelector.user);

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleClose = () => {
    navigate('/');
  };

  const handleAfterClose = () => {
    setValue('profile');
  };

  const handleLogout = () => {
    logout();
  };

  if (!loggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleClose}
      onAfterClose={handleAfterClose}
      contentClassName={bem('')}
    >
      <Panel>
        <PanelClose onClick={handleClose} />
        <Tabs className={bem('tabs')} value={value} onChange={handleChange}>
          <Tab value="profile">Profile</Tab>
          <Tab value="edit">Edit details</Tab>
          <Tab value="password">Change password</Tab>
        </Tabs>

        <TabContent value={value} index="profile">
          <ProfileScreen {...{ user }} />
          <ButtonGroup className={bem('buttons')}>
            <Button size="medium" color="red" onClick={handleLogout}>
              Logout
            </Button>
          </ButtonGroup>
        </TabContent>

        <TabContent value={value} index="edit">
          <EditAccount />
        </TabContent>

        <TabContent value={value} index="password">
          <ChangePassword />
        </TabContent>
      </Panel>
    </Modal>
  );
};

export default AccountModal;
