import React, { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { Container } from 'react-pixi-fiber';
import { useRecoilValue } from 'recoil';

import Background from './Background';
import Board from './Board';

import useLoader from './useLoader';
import { isMenuOpenState } from '@/use/useMenuState';

const Game = () => {
  const { loadAssets } = useLoader();
  const isMenuOpen = useRecoilValue(isMenuOpenState);
  const blurFilter = new PIXI.filters.BlurFilter(4);

  const filters = !isMenuOpen ? [] : [blurFilter];

  useEffect(() => {
    loadAssets();
  }, []);

  return (
    <Container {...{ filters }}>
      <Background />
      <Board />
    </Container>
  );
};

export default Game;
