import React from 'react';
import { Sprite, Container, PointLike } from 'react-pixi-fiber';

import useLoader from '@/game/useLoader';

export type Props = {
  anchor: PointLike;
  positionV: 'top' | 'bottom';
  positionH: 'left' | 'right';
  x?: number;
  y?: number;
};

const BorderLine = ({ anchor, positionV, positionH, x, y }: Props) => {
  const { loader, loaded } = useLoader();

  if (!loaded) return null;

  const name = `corner-${positionV}-${positionH}`;
  const texture = loader.resources[name].texture;

  return (
    <Container>
      <Sprite {...{ anchor, texture, x, y }} />
    </Container>
  );
};

export default BorderLine;
