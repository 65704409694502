import React from 'react';

export interface ITypographyProps {
  variant?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'social' | 'th' | 'bevel';

  mb?: 'medium' | 'large';

  /**
   * The tag / component to use for the wrapper
   */
  component?: React.ElementType;
}

const Typography: React.FC<
  ITypographyProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  variant = 'm',
  children,
  component: Component = 'p',
  className = '',
  mb,
  ...rest
}) => {
  return (
    <Component
      className={`typography typography--${variant} ${className}`}
      style={{ marginBottom: mb ? `var(--margin-${mb})` : undefined }}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typography;
