import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { modalState, openModal, closeModal, ModalProps } from './useModalState';

const useModal = (modalId: string) => {
  const modal = useRecoilValue(modalState);
  const isOpen = modal?.modalId === modalId;

  const open = useCallback(
    (props: ModalProps = {}) => openModal(modalId, props),
    [modalId],
  );
  const close = useCallback(() => closeModal(modalId), [modalId]);

  return [isOpen, { open, close }, modal?.props] as const;
};

export default useModal;
