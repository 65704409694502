import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@/ui-components/Button';
import Input from '@/ui-components/Input';
import ButtonGroup from '@/ui-components/ButtonGroup';
import FieldGroup from '@/ui-components/FieldGroup';
import ErrorsMessage from '@/ui-components/ErrorsMessage';

import { signup, SignupValuesType } from '@/framework/user';

import { setApiErrors } from '@/utils/setApiErrors';

const validationSchema = Yup.object({
  email: Yup.string().email('Not valid email').required('Required email'),
  username: Yup.string().required('Required username'),
  password: Yup.string().required('Required password'),
});

const SignupForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignupValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: SignupValuesType) => {
    try {
      await signup(values);
      navigate('/');
    } catch (err) {
      setApiErrors(err.errors, setError);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorsMessage {...{ errors }} />

      <FieldGroup>
        <Input
          type="email"
          placeholder="Email"
          autoComplete="email"
          error={errors.email?.message}
          {...register('email')}
        />
        <Input
          placeholder="Username"
          autoComplete="username"
          error={errors.username?.message}
          {...register('username')}
        />
        <Input
          type="password"
          autoComplete="new-password"
          placeholder="Password"
          error={errors.password?.message}
          {...register('password')}
        />
      </FieldGroup>

      <ButtonGroup>
        <Button size="medium" type="submit" loading={isSubmitting}>
          Sign up
        </Button>
      </ButtonGroup>
    </form>
  );
};

export default SignupForm;
