import React from 'react';
import Flag from 'react-flagkit';

import Typography from '@/ui-components/Typography';

import useBem from '@/use/useBem';

type PlayerNameProps = {
  name?: string;
  rank?: number;
  country?: string;
};

const PlayerName = (props: PlayerNameProps) => {
  const { country, name } = props;
  const { bem } = useBem('player');

  return (
    <div className={bem('name')}>
      {country && (
        <span className={bem('flag')}>
          <Flag country={country.toUpperCase()} size={15} />
        </span>
      )}
      <Typography variant="m" component="span">
        {name}
      </Typography>
    </div>
  );
};

export default PlayerName;
