import { IPlayer2 } from '@/framework/player';
import { GemType } from '@/framework/gem';

export const mapPidToColor = (
  pid: string,
  player1: Pick<IPlayer2, 'id' | 'colour'>,
  player2: Pick<IPlayer2, 'id' | 'colour'>,
): GemType => {
  if (pid === player1?.id && player1?.colour) return player1?.colour;
  if (pid === player2?.id && player2?.colour) return player2?.colour;
  return 'neutral';
};
