import type { GemType } from './types';

export const getGemTypeRank = ({
  rank,
  colour,
}: {
  rank: number;
  colour: GemType;
}): GemType => {
  if (rank === 1) {
    return 'gold';
  }
  if (rank === 2) {
    return 'silver';
  }
  if (rank === 3) {
    return 'bronze';
  }

  return colour;
};
