import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import * as colyseus from '@/colyseus/colyseus.service';
import { setupRoom } from '@/colyseus/colyseus-room';

import { openModal, closeModal } from '@/use/useModal';
import { isMenuOpenState } from '@/use/useMenuState';

import useExitGame from './useExitGame';
import useStartGame from './useStartGame';

const useReconnectGame = () => {
  const navigate = useNavigate();
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const { clearGame } = useExitGame();
  const startGame = useStartGame();

  const reconnectGame = async (gameId: string) => {
    console.log('reconnectToGame');
    /**
     * Exit any previous game if present
     */
    clearGame();

    openModal('connect');

    try {
      const room = await colyseus.reconnect(gameId);

      setupRoom(room);
      /**
       * To change:
       * Need to know what type we are reconnecting to.
       */
      startGame(gameId, 'random');
      setIsMenuOpen(false);
      closeModal();
    } catch (err) {
      clearGame();

      openModal('not-found', {
        onRequestClose: () => {
          closeModal();
          navigate('/');
        },
      });

      throw err;
    }
  };

  return reconnectGame;
};

export default useReconnectGame;
