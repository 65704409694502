import React from 'react';
import Icon from '@/ui-components/Icon';
import Typography from '@/ui-components/Typography';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Variant
   */
  variant: 'error' | 'success';
}

const StatusMessage: React.FC<Props> = ({ children, className = '', variant }) => {
  const map = {
    error: 'remove',
    success: 'check',
  };

  return (
    <div className={`status-message status-message--${variant} ${className}`}>
      <Icon name={map[variant]} className={`status-message__icon`} />
      <Typography variant="s" className={`status-message__content`}>
        {children}
      </Typography>
    </div>
  );
};

export default StatusMessage;
