import React, { useEffect, useState } from 'react';

import * as leaderboard from '@/api/leaderboard.service';

import Typography from '@/ui-components/Typography';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';

import LeaderboardItem from './LeaderboardItem';

const Leaderboard = () => {
  const { bem } = useBem('leaderboard');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetch() {
      const { data } = await leaderboard.getLeaderboard();
      setUsers(data.users);
    }

    fetch();
    return () => {
      setUsers([]);
    };
  }, []);

  if (users.length === 0) {
    return <Loading />;
  }

  return (
    <div className={bem('wrap')}>
      <Typography variant="l" className={bem('h')}>
        Leaderboard
      </Typography>

      <div className={bem('table-wrap')}>
        <table className={bem('table')}>
          <thead>
            <tr>
              <th className={bem('rank')}>
                <Typography variant="th" component="span">
                  Rank
                </Typography>
              </th>
              <th className={bem('name')}>
                <Typography variant="th" component="span">
                  Name
                </Typography>
              </th>
              <th className={bem('score')}>
                <Typography variant="th" component="span">
                  Score
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((player, index) => (
              <LeaderboardItem key={index} {...player} rank={index + 1} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
