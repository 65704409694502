import { useMediaQuery } from 'react-responsive';

const useBreakpoint = () => {
  const breakpoints = {
    m: { min: 0, max: 375 },
    t: { min: 376, max: 768 },
    l: { min: 769, max: 1024 },
    d: { min: 1025, max: null },
  };

  const useQuery = (breakpoint: any) => {
    const min = breakpoint.min ? `(min-width: ${breakpoint.min}px)` : '';
    const max = breakpoint.max ? `(max-width: ${breakpoint.max}px)` : null;
    const arr = [];

    if (min) {
      arr.push(min);
    }

    if (max) {
      arr.push(max);
    }

    const query = arr.join(' and ');

    return useMediaQuery({ query });
  };

  const breakpoint = {
    d: useQuery(breakpoints.d),
    l: useQuery(breakpoints.l),
    t: useQuery(breakpoints.t),
    m: useQuery(breakpoints.m),
  };

  return {
    breakpoint,
  };
};

export default useBreakpoint;
