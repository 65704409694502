import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import * as colyseus from '@/colyseus/colyseus.service';
import { setupRoom } from '@/colyseus/colyseus-room';

import { openModal, closeModal } from '@/use/useModal';
import { isMenuOpenState } from '@/use/useMenuState';

import useExitGame from './useExitGame';
import useStartGame from './useStartGame';

/**
 * When player joins game from the friend invite
 */

const useJoinGame = () => {
  const navigate = useNavigate();
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const { clearGame } = useExitGame();
  const startGame = useStartGame();

  const joinGame = async (gameId: string) => {
    /**
     * Exit any previous game if present
     */
    clearGame();

    openModal('connect');

    try {
      const room = await colyseus.join(gameId);

      setupRoom(room);
      startGame(gameId, 'invite');
      setIsMenuOpen(false);
      closeModal();
    } catch (err) {
      clearGame();

      openModal('not-found', {
        onRequestClose: () => {
          closeModal();
          navigate('/');
        },
      });

      throw err;
    }
  };

  return joinGame;
};

export default useJoinGame;
