import { resetRecoil } from 'recoil-nexus';
import { boardStates, rematchStates } from './boardState';

export const resetBoard = () => {
  boardStates.forEach(resetRecoil);
};

export const resetBoardRematch = () => {
  rematchStates.forEach(resetRecoil);
};
