import React from 'react';
import classNames from 'classnames';
import { FieldErrors, FieldValues } from 'react-hook-form';

import StatusMessage from '@/ui-components/StatusMessage';

import useBem from '@/use/useBem';

export type ErrorsMessageProps<T> = React.HTMLAttributes<HTMLDivElement> & {
  errors: FieldErrors<T>;
};

const ErrorsMessage = <T extends FieldValues>({
  errors,
  className = '',
}: ErrorsMessageProps<T>) => {
  const { bem } = useBem('error-message');
  const keys = Object.keys(errors);

  // If no errors available, return null
  if (keys.length === 0) return null;

  // // Pick first error from the array
  const error = errors[keys[0]];

  return (
    <StatusMessage variant="error" className={classNames([bem(''), className])}>
      {error.message}
    </StatusMessage>
  );
};

export default ErrorsMessage;
