import { useState, useEffect } from 'react';
import useOrientation from '@/use/useOrientation';
import useBreakpoint from '@/use/useBreakpoint';

type Layout = 'desktop' | 'landscape' | 'portrait';

const useLayout = () => {
  const [layout, setLayout] = useState<Layout>('desktop');
  const { breakpoint } = useBreakpoint();
  const { orientation } = useOrientation();

  useEffect(() => {
    if (breakpoint.d) {
      setLayout('desktop');
    } else {
      if (orientation.landscape) {
        setLayout('landscape');
      } else {
        setLayout('portrait');
      }
    }
  }, [breakpoint, orientation]);

  return {
    layout,
  };
};

export default useLayout;
